<template>
    <router-view />
</template>

<script>

export default {
  name: 'ParentLayout'
}

</script>
