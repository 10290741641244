<template>
  <div>
    <nav-bar />
    <aside-menu :menu="$store.state.userMenu" />
    <router-view />
    <footer-bar />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import { apiService } from '@/services'
import { mapActions } from 'vuex'

export default {
  name: 'AdminLayout',
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  mounted () {
    document.documentElement.classList.add('has-aside-left',
      'has-aside-mobile-transition',
      'has-navbar-fixed-top',
      'has-aside-expanded')
  },
  destroyed () {
    document.documentElement.classList.remove(...document.documentElement.classList)
  },
  created () {
    this.getUserDetails()
  },
  methods: {
    ...mapActions({ showError: 'error/show' }),
    getUserDetails () {
      apiService.getAll('/me').then(response => {
        this.$store.commit('user', {
          name: response.data.user.name,
          email: response.data.user.email,
          menu: response.data.menu,
          avatar: response.data.user.avatar
        })
      }).catch(error => {
        this.showError(error)
      })
    }
  }
}
</script>
